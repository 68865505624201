<template>
	<div>
		<div
			class="app-container"
			:class="{ 'app-mobile': isDevice, 'app-mobile-dark': theme === 'dark' }"
		>
			<!-- <div>
				<button @click="resetData">
					Clear Data
				</button>
				<button :disabled="updatingData" @click="addData">
					Add Data
				</button>
			</div> -->
			


            <chat-window
                height="100vh"
                :show-emojis="false"
                :current-user-id="myUserId"
                :rooms="rooms"
                :messages="messages"
                :room-id="roomId"
                :messages-loaded="messagesLoaded"
                :show-add-room="false"
                :show-audio="false"
                :show-reaction-emojis="false"
                :rooms-loaded="true"
                :single-room="true"
                :message-actions="[]"
                :text-messages="text_messages"
                @fetch-messages="fetchMessages"
                @send-message="sendMessage"
            />

			<!-- <div class="version-container">
				v1.0.0
			</div> -->
		</div>
	</div>
</template>

<script>

import ChatWindow from 'vue-advanced-chat'
import 'vue-advanced-chat/dist/vue-advanced-chat.css'
import mqtt from 'mqtt'

// import ChatContainer from './ChatContainer'

export default {
	components: {
        // ChatContainer
        ChatWindow
	},

	data() {
		return {
			theme: 'light',
			showChat: true,
            rooms: [],
            messages: [],
            messagesLoaded: false,
            roomId: '',
			isDevice: false,
			showDemoOptions: true,
            updatingData: false,
            myUserId: 1,

            text_messages: {
                ROOMS_EMPTY: 'Đang tải cuộc hội thoại...',
                ROOM_EMPTY: 'Đang tải cuộc hội thoại...',
                SEARCH: 'Tìm kiếm',
                CONVERSATION_STARTED: 'Cuộc hội thoại bắt đầu lúc :',
                NEW_MESSAGES: 'Tin nhắn mới',
            },
            
            // for mqtt
            client: null,
            connection: {
                host: '103.15.50.244',
                port: 8083,
                clean: true, // Reserved session
                connectTimeout: 4000, // Time out
                reconnectPeriod: 4000, // Reconnection interval
                // Certification Information
                clientId: (Math.random() + 1).toString(36).substring(2) + (Math.random() + 1).toString(36).substring(2),
            }
		}
	},

	computed: {
		showOptions() {
			return !this.isDevice || this.showDemoOptions
        }
	},

	watch: {
        
	},

	mounted() {
        this.createMQTTConnection();
        this.getMyId();
		this.isDevice = window.innerWidth < 500
		window.addEventListener('resize', ev => {
			if (ev.isTrusted) this.isDevice = window.innerWidth < 500
		})
	},

	methods: {
        async getMyId() {
            var token = this.$route.query.token;
            if(!token) return;
            var my_info = await this.$store.dispatch('chat/get_my_info', { token });
            if(my_info) {
                this.myUserId = my_info.Id;
            }
        },
        createMQTTConnection() {
            var self = this;
            const { host, port, ...options } = this.connection;
            const connectUrl = `ws://${host}:${port}`;
            try {
                this.client = mqtt.connect(connectUrl, options);
                console.log('this..d.sf.sdf', this.client);

                this.client.on('connect', () => {
                    self.fetchRooms();
                    console.log('Connection succeeded!')
                })
                this.client.on('error', error => {
                    console.log('Connection failed', error);
                })
                this.client.on('message', (topic, message) => {
                    // this.receiveNews = this.receiveNews.concat(message)
                    console.log(`Received message ${message} from topic ${topic}`)
                    try {
                        var formattedMessage = self.formatMessage(JSON.parse(message));
                        self.handleNewMessage(topic, formattedMessage);
                    }
                    catch(e) {
                        console.log(e);
                    }
                })
            } catch (error) {
                console.log('mqtt.connect error', error)
            }
        },
        doSubscribe(topic, qos) {
            this.client.subscribe(topic, { qos }, (error, res) => {
                if (error) {
                    console.log('Subscribe to topics error', error)
                    return
                }
                console.log('Subscribe to topics res', res)
            })
        },
        doPublish(topic, message, qos) {
            this.client.publish(topic, message, qos, error => {
                if (error) {
                    console.log('Publish error', error)
                }
            })
        },
        doPostMsg(data) {
            // Call to Android App
            if(data._unread) {
                if (window.nativeApp) window.nativeApp.unread(data._unread);
            }
            if(data._new) {
                if (window.nativeApp) window.nativeApp.newMessage(data._new);
            }
            
            
            
            // Call to IOS App
            else  if ((window['webkit']) && ( window['webkit'].messageHandlers) && (( window['webkit'].messageHandlers.nativeApp))) window['webkit'].messageHandlers.nativeApp.postMessage({"newmessage": data}); 
        },
        // eslint-disable-next-line no-unused-vars
        async fetchMessages({ room, options = {} }) {
            this.messagesLoaded = false;
            this.roomId = room.roomId;
            var token = this.$route.query.token;
            if(!token) return;
            var payload = { roomId: room.roomId, token };
            var messages = await this.$store.dispatch('chat/get_history', payload);
            // console.log("fdsfsdfsdf messages", messages);
            messages = messages.Message;
            
            if(messages) {
                var tmp_messages = [];
                for(var i = 0; i < messages.length; i++) {
                    if(messages[i].Typemess == 1) {
                        var tmp_object = {
                            content: messages[i].Content,
                            date: new Date(messages[i].Create_time * 1000).toLocaleDateString(),
                            seconds: messages[i].Create_time,
                            senderId: messages[i].User_id,
                            sender_id: messages[i].User_id,
                            timestamp: new Date(messages[i].Create_time * 1000).getHours() + ':' + new Date(messages[i].Create_time * 1000).getMinutes(),
                            username: null,
                            _id: messages[i].Id,
                        };
                        tmp_messages.push(tmp_object);
                    }
                }
                this.messages = tmp_messages;
            } else {
                this.messages = [];
            }

            // this.doPublish(room.topic, JSON.stringify({User_id: this.myUserId, Typemess: 3}), 0);
            
            this.messagesLoaded = true;
        },

        async fetchRooms() {
            var token = this.$route.query.token;
            if(!token) return;
            var rooms = await this.$store.dispatch('chat/request_support', { token });
            var tmp_rooms = [];
            for(var i in rooms) {
                var tmp_object = {
                    roomId: rooms[i].Id,
                    avatar: rooms[i].Thumbnail || '/media/logos/detective.png',
                    roomName: rooms[i].Description,
                    topic: rooms[i].Room_name,
                    unreadCount: rooms[i].Unread || 0,
                    users: [
                        {
                            _id: 1,
                            username: "Admin"
                        }
                    ]
                };
                if(rooms[i].LastMessage) {
                    tmp_object["lastMessage"] = {
                        content: rooms[i].LastMessage.Content,
                        distributed: true,
                        new: false,
                        seen: undefined,
                        sender_id: rooms[i].LastMessage.User_id,
                        timestamp: new Date(rooms[i].LastMessage.Create_time * 1000).toLocaleDateString(),
                    };
                }
                tmp_rooms.push(tmp_object);
                this.doSubscribe(rooms[i].Room_name, 0);


                if(rooms[i].Unread) {
                    this.doPostMsg({_unread: rooms[i].Unread});
                }


                // mark unread khi query markread
                if(this.$route.query.markread == '1') {
                    this.doPublish(rooms[i].Room_name, JSON.stringify({User_id: this.myUserId, Typemess: 3}), 0);
                }
                
            }
            this.rooms = tmp_rooms;
        },

        sendMessage({ content, roomId }) {
            var topic = null;
            var room = this.rooms.find(r => r.roomId == roomId);
            if(room) topic = room.topic;

            if(topic) {
                var mqtt_message = {
                    User_id: this.myUserId,
                    Content: content,
                    Typemess: 1
                }
                // this.messages = this.messages.concat([message]);
                this.doPublish(topic, JSON.stringify(mqtt_message), 0);
                this.doPublish(topic, JSON.stringify({User_id: this.myUserId, Typemess: 3}), 0);
            }
        },

        formatMessage(message) {
            if(message.Typemess == 1) {
                var tmp_object = {
                    content: message.Content,
                    date: new Date().toLocaleDateString(),
                    seconds: Math.floor(new Date().getTime() / 1000),
                    senderId: message.User_id,
                    sender_id: message.User_id,
                    timestamp: new Date().getHours() + ':' + new Date().getMinutes(),
                    username: null,
                    _id: new Date().getTime(),
                };
                return tmp_object;
            } else if(message.Typemess == 3) {
                return message;
            } else {
                return message;
            }
            
        },
        // eslint-disable-next-line no-unused-vars
        handleNewMessage(topic, message) {
            // find roomId by topic
            var roomIdx = this.rooms.findIndex(r => r.topic == topic);
            if(roomIdx > -1) {
                var roomId = this.rooms[roomIdx].roomId;
                if(!message.Typemess) {
                    if(message.senderId != this.myUserId) {
                        this.doPostMsg({_new: 1});
                        this.rooms[roomIdx].lastMessage = message;
                        if(this.roomId == roomId) {
                            this.messages = this.messages.concat([message]);
                            // this.doPublish(this.rooms[roomIdx].topic, JSON.stringify({User_id: this.myUserId, Typemess: 3}), 0);
                        } else {
                            this.rooms[roomIdx].unreadCount = this.rooms[roomIdx].unreadCount ? this.rooms[roomIdx].unreadCount + 1 : 1;
                        }
                    } else {
                        this.rooms[roomIdx].lastMessage = message;
                        this.messages = this.messages.concat([message]);
                    }
                }
                if(message.Typemess == 3) {
                    this.rooms[roomIdx].unreadCount = 0;
                }
            }
        }
	}
}
</script>

<style lang="scss">
body {
	background: #fafafa;
	margin: 0;
}
input {
	-webkit-appearance: none;
}

.app-container {
	font-family: 'Quicksand', sans-serif;
	padding: 20px 30px 30px;
}

.app-mobile {
	padding: 0;

	&.app-mobile-dark {
		background: #131415;
	}

	.user-logged {
		margin: 10px 5px 0 10px;
	}

	select {
		margin: 10px 0;
	}

	.button-theme {
		margin: 10px 10px 0 0;

		.button-github {
			height: 23px;

			img {
				height: 23px;
			}
		}
	}
}

.user-logged {
	font-size: 12px;
	margin-right: 5px;
	margin-top: 10px;

	&.user-logged-dark {
		color: #fff;
	}
}

select {
	height: 20px;
	outline: none;
	border: 1px solid #e0e2e4;
	border-radius: 4px;
	background: #fff;
	margin-bottom: 20px;
}

.button-theme {
	float: right;
	display: flex;
	align-items: center;

	.button-light {
		background: #fff;
		border: 1px solid #46484e;
		color: #46484e;
	}

	.button-dark {
		background: #1c1d21;
		border: 1px solid #1c1d21;
	}

	button {
		color: #fff;
		outline: none;
		cursor: pointer;
		border-radius: 4px;
		padding: 6px 12px;
		margin-left: 10px;
		border: none;
		font-size: 14px;
		transition: 0.3s;
		vertical-align: middle;

		&.button-github {
			height: 30px;
			background: none;
			padding: 0;
			margin-left: 20px;

			img {
				height: 30px;
			}
		}

		&:hover {
			opacity: 0.8;
		}

		&:active {
			opacity: 0.6;
		}

		@media only screen and (max-width: 768px) {
			padding: 3px 6px;
			font-size: 13px;
		}
	}
}

.version-container {
	padding-top: 20px;
	text-align: right;
	font-size: 14px;
	color: grey;
}
</style>
